@font-face {
    font-family: 'Open Sauce Two';
    font-weight: 200;
    src: url('assets/fonts/OpenSauceTwo-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sauce Two';
    font-weight: 400;
    src: url('assets/fonts/OpenSauceTwo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sauce Two';
    font-weight: 300;
    src: url('assets/fonts/OpenSauceTwo-Medium.ttf') format('truetype');

}


@font-face {
    font-family: 'Open Sauce Two';
    font-weight: 500;
    src: url('assets/fonts/OpenSauceTwo-SemiBold.ttf') format('truetype');

}

@font-face {
    font-family: 'Open Sauce Two';
    font-weight: 600;
    src: url('assets/fonts/OpenSauceTwo-Bold.ttf') format('truetype');

}

body {
    margin: 0;
    font-family: "Open Sauce Two" , sans-serif;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(180deg, #294278 0%, #2C2960 100%),
linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));

    color: #fff;
    min-height: 100vh;
}

button {
    cursor: pointer;
}

.ReactModalPortal {
    z-index: 9999;
    position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}